function App() {
  const searchParams = new URLSearchParams(document.location.search);
  const theme = searchParams.get('theme') === 'light' ? 'light' : 'dark';

  return (
    <div className={`app theme-${theme}`}>
      <div className='wrapper'>
        <h1 className='title'>What's new</h1>
        <p className='update_date'>11 Nov, 2023</p>
        <div className='info-field'>
          <img className='info-field__img' src='/images/chats.png' />
          <p className='info-field__title'>Unlimited Accounts</p>
          <p className='info-field__description'>
            While Telegram users can set up 3 accounts as maximum, 
            Nicegram gives you an opportunity to create dozens and even hundreds of profiles!
            All you need to do is go to Settings and tap Add Account. 
            Separating chats with friends from business communication has never been easier! 
            Now your personal, business, and Telegram channel administrator profiles can coexist parallely without ever getting mixed up.
          </p>
        </div>
        <div className='info-field'>
          <p className='info-field__title'>Instant Responses</p>
          <p className='info-field__description'>
            Say goodbye to long waiting times!
          </p>
        </div>
        <div className='info-field'>
          <img className='info-field__img' src='/images/bot.png' />
          <p className='info-field__description'>
            Our Al assistant, Lily, now starts typing an answer to your questions immediately, providing prompt responses.
          </p>
        </div>
        <div className='info-field'>
          <p className='info-field__title'>Enhanced Stability</p>
          <p className='info-field__description'>
            We've diligently worked on resolving various bugs and issues, ensuring a smoother and more reliable experience for all users.
          </p>
        </div>
        <div className='info-field'>
          <p className='info-field__description'>
            We hope you enjoy these updates and have a seamless experience with the app!
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
